const CertikIcon = () => (
  <svg width="279" height="70" viewBox="0 0 279 70" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M57.4419 11.5226L57.7933 13.7195C58.057 15.7405 58.1888 17.7616 58.1448 19.8267C58.1448 24.3521 57.6176 28.8337 56.5631 33.2273C55.1571 39.1588 52.6967 44.8266 49.3136 49.9233C45.4032 55.7229 39.7354 61.3907 31.5192 66.048L30.5087 66.6192L29.4981 66.048C21.282 61.4786 15.6142 55.7668 11.7038 49.9233C8.27674 44.8266 5.86022 39.2027 4.45425 33.2273C3.39977 28.8337 2.87253 24.3521 2.87253 19.8267C2.87253 17.8056 2.9604 15.7405 3.22402 13.7195L3.53158 11.5226C3.57551 11.3469 3.57551 11.1711 3.53158 10.9954C3.48764 10.8196 3.4437 10.6439 3.31189 10.5121C3.22402 10.3803 3.09221 10.2485 2.9604 10.1606C2.82859 10.0727 2.65284 10.0288 2.52103 9.98484C2.34529 9.94091 2.16954 9.94091 1.99379 9.98484C1.81805 10.0288 1.6423 10.1167 1.51049 10.2045C1.37868 10.2924 1.24687 10.4242 1.159 10.556C1.07112 10.7318 1.02719 10.9075 0.98325 11.0833L0.631759 13.2801C0.368139 15.433 0.236328 17.6298 0.236328 19.8267C0.236328 24.5718 0.807506 29.273 1.90592 33.8864C2.65285 37.0059 3.66339 40.0815 4.93755 43.0252C6.21171 45.969 7.7495 48.7809 9.5509 51.4611C13.6809 57.6122 19.6563 63.6315 28.2679 68.3767L29.8936 69.2993C30.1133 69.3872 30.3329 69.4311 30.5526 69.4311C30.7723 69.4311 30.992 69.3872 31.2117 69.2993L32.8373 68.3767C41.405 63.5876 47.4243 57.5682 51.5543 51.4611C55.1571 46.1008 57.7494 40.1254 59.1993 33.8864C60.2977 29.273 60.825 24.5718 60.825 19.8267C60.825 17.6298 60.6932 15.433 60.4295 13.2362L60.0781 11.0393C60.0341 10.8636 59.9902 10.6878 59.9023 10.5121C59.8144 10.3803 59.6826 10.2485 59.5508 10.1606C59.419 10.0727 59.2433 9.98485 59.0675 9.94091C58.8918 9.89697 58.716 9.89697 58.5403 9.94091C58.3645 9.98485 58.1888 10.0288 58.013 10.1167C57.8812 10.2045 57.7494 10.3363 57.6615 10.4682C57.5737 10.6 57.4858 10.7757 57.4419 10.9514V11.5226Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.9129 10.0287L30.5965 3.83366L50.6756 10.1605C50.8513 10.2045 51.0271 10.2045 51.2028 10.2045C51.3785 10.2045 51.5543 10.1605 51.73 10.0727C51.9058 9.98479 52.0376 9.89692 52.1694 9.72118C52.3012 9.58937 52.3891 9.41362 52.3891 9.23787C52.433 9.06212 52.433 8.88638 52.433 8.71063C52.433 8.53488 52.3891 8.35914 52.3012 8.18339C52.2133 8.00765 52.1255 7.87584 51.9937 7.74403C51.8618 7.61222 51.6861 7.52434 51.5104 7.52434L30.9919 1.02172C30.7283 0.933842 30.4647 0.933842 30.2011 1.02172L10.166 7.34859C9.99021 7.39253 9.8584 7.4804 9.68265 7.56827C9.55084 7.70008 9.41903 7.83189 9.3751 8.00764C9.28722 8.18339 9.24329 8.35913 9.24329 8.53488C9.24329 8.71063 9.24329 8.88638 9.28722 9.06213C9.33116 9.23787 9.41903 9.41361 9.50691 9.54542C9.59478 9.67723 9.77053 9.80905 9.94627 9.89692C10.0781 9.98479 10.2538 10.0287 10.4735 10.0287C10.5614 10.0727 10.7371 10.0727 10.9129 10.0287Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.20298 11.9181L29.3664 68.6843C29.4542 68.9039 29.63 69.0797 29.8057 69.2115C30.2451 69.4751 30.7723 69.4751 31.2117 69.2115C31.4314 69.0797 31.6071 68.9039 31.739 68.6843L48.9182 35.5121L48.9621 35.4682L59.9463 11.8742C60.0342 11.6984 60.0781 11.5227 60.0781 11.3469C60.0781 11.1712 60.0781 10.9954 59.9902 10.8197C59.9463 10.6439 59.8584 10.4682 59.7266 10.3364C59.6387 10.2046 59.463 10.0728 59.3312 9.98489C59.1994 9.89702 59.0236 9.85308 58.8039 9.85308C58.6282 9.85308 58.4524 9.85308 58.2767 9.89702C58.101 9.94095 57.9252 10.0288 57.7934 10.1606C57.6616 10.2924 57.5298 10.4243 57.4858 10.6L46.5017 34.194L30.5087 65.0815L3.53162 10.6C3.44375 10.4682 3.31194 10.2924 3.22407 10.1606C3.09226 10.0288 2.91651 9.94095 2.74076 9.89702C2.56501 9.80914 2.38927 9.80914 2.21352 9.85308C2.03777 9.85308 1.86203 9.89702 1.68628 9.98489C1.55447 10.0728 1.37873 10.2046 1.29085 10.3364C1.20298 10.4682 1.11511 10.6439 1.07117 10.8197C1.02723 10.9954 0.983296 11.1712 1.02723 11.3469C1.07117 11.5666 1.1151 11.7424 1.20298 11.9181Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.6914 37.2256H46.0622C46.2379 37.2256 46.4137 37.1817 46.5894 37.1378C46.7652 37.0938 46.897 36.962 46.9848 36.8302C47.1166 36.6984 47.2045 36.5666 47.2485 36.3908C47.3803 36.0393 47.3803 35.6878 47.2485 35.3364C47.2045 35.1606 47.0727 35.0288 46.9848 34.897C46.853 34.7652 46.7212 34.6773 46.5894 34.5894C46.4137 34.5455 46.2379 34.5016 46.0622 34.5016H14.6914C14.5157 34.5016 14.3399 34.5455 14.1642 34.5894C13.9884 34.6334 13.8566 34.7652 13.7687 34.897C13.6369 35.0288 13.549 35.1606 13.5051 35.3364C13.3733 35.6878 13.3733 36.0393 13.5051 36.3908C13.549 36.5666 13.6369 36.6984 13.7687 36.8302C13.9005 36.962 14.0323 37.0499 14.1642 37.1378C14.3399 37.1817 14.5157 37.1817 14.6914 37.2256ZM102.125 25.4067H81.7387C81.563 25.4067 81.3872 25.4506 81.2115 25.4945C81.0358 25.5385 80.9039 25.6703 80.8161 25.8021C80.6843 25.9339 80.5964 26.0657 80.5525 26.2415C80.5085 26.4172 80.4646 26.593 80.4646 26.7687V43.6843C80.4646 43.8601 80.5085 44.0358 80.5525 44.2116C80.5964 44.3873 80.6843 44.5191 80.8161 44.6509C80.9479 44.7827 81.0797 44.8706 81.2115 44.9585C81.3872 45.0024 81.563 45.0464 81.7387 45.0464H102.125V47.9022H81.7387C81.4751 47.9022 81.2115 47.8583 80.9479 47.8144C80.6843 47.7704 80.4206 47.6826 80.157 47.5947C79.8934 47.5068 79.6737 47.375 79.454 47.2432C79.2344 47.0674 79.0586 46.8917 78.8389 46.7159C78.4435 46.3205 78.1799 45.8811 77.9602 45.3978C77.8723 45.1342 77.7844 44.8706 77.7405 44.607C77.6966 44.3434 77.6526 44.0798 77.6526 43.8161V26.8126C77.6526 26.549 77.6966 26.2854 77.7405 26.0218C77.7844 25.7582 77.8723 25.4945 77.9602 25.2309C78.0481 24.9673 78.1799 24.7037 78.3117 24.484C78.4435 24.2643 78.6192 24.0446 78.8389 23.8689C79.0147 23.6931 79.2344 23.5174 79.454 23.3416C79.6737 23.2098 79.8934 23.078 80.157 22.9901C80.4206 22.9023 80.6843 22.8144 80.9479 22.7705C81.2115 22.7265 81.4751 22.6826 81.7387 22.6826H102.125V25.4067ZM140.394 22.5508V25.4067H120.754V33.7986H136.528V36.6984H120.754V45.0903H140.394V47.9462H118.03V22.5508H140.394Z"
      fill="white"
    />
    <path
      d="M180.86 26.8566V34.2819C180.86 34.5455 180.816 34.8091 180.772 35.0727C180.728 35.3364 180.64 35.6 180.552 35.8636C180.464 36.1272 180.333 36.3908 180.201 36.6105C180.025 36.8302 179.893 37.0499 179.674 37.2256C179.498 37.4014 179.278 37.5771 179.059 37.7529C178.839 37.8847 178.619 38.0165 178.356 38.1044C178.092 38.1922 177.828 38.2801 177.565 38.368C177.301 38.4119 177.037 38.4559 176.774 38.4559H173.039L180.728 47.9022H177.037L169.392 38.4559H160.429C159.99 38.4559 159.551 38.4119 159.111 38.3241V47.9022H156.343V22.5947H176.818C177.081 22.5947 177.345 22.6387 177.609 22.6826C177.872 22.7265 178.136 22.8144 178.399 22.9023C178.663 22.9902 178.883 23.122 179.102 23.2977C179.542 23.6053 179.893 24.0007 180.201 24.4401C180.333 24.6598 180.464 24.9234 180.552 25.187C180.64 25.4506 180.728 25.7142 180.772 25.9779C180.816 26.2854 180.86 26.593 180.86 26.8566ZM160.385 35.6439H176.774C176.95 35.6439 177.125 35.6 177.301 35.556C177.477 35.5121 177.609 35.4242 177.696 35.2485C177.828 35.1167 177.916 34.9849 177.96 34.8091C178.004 34.6334 178.048 34.4576 178.048 34.2819V26.8566C178.048 26.5051 177.916 26.1536 177.696 25.89C177.477 25.6264 177.125 25.4506 176.774 25.4506H160.385C160.21 25.4506 160.034 25.4946 159.858 25.5385C159.682 25.5824 159.551 25.7142 159.463 25.846C159.331 25.9778 159.243 26.1097 159.199 26.2854C159.155 26.4611 159.111 26.6369 159.111 26.8126V34.2379C159.111 34.5894 159.243 34.9409 159.551 35.2046C159.726 35.4682 160.078 35.6439 160.385 35.6439Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M195.535 22.5508H220.051V25.4067H209.199V47.9022H206.431V25.4067H195.535V22.5508ZM234.99 47.9462V22.5947H237.758V47.9462H234.99ZM274.621 22.5508H278.18L267.942 35.2485L278.223 47.9462H274.621L265.482 36.6984H257.573V47.9462H254.805V22.5947H257.617V33.8425H265.526L274.621 22.5508Z"
      fill="white"
    />
  </svg>
)

export default CertikIcon
