const DCoin = () => (
  <svg width="57" height="57" viewBox="0 0 57 57" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M28.6953 56.6632C44.1593 56.6632 56.6953 44.1272 56.6953 28.6632C56.6953 13.1992 44.1593 0.663208 28.6953 0.663208C13.2313 0.663208 0.695312 13.1992 0.695312 28.6632C0.695312 44.1272 13.2313 56.6632 28.6953 56.6632Z"
      fill="#C3A634"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.8793 26.2307H31.4288V30.2312H23.8793V38.6627H28.6411C30.5258 38.6627 32.0676 38.409 33.2681 37.898C34.4686 37.3887 35.4101 36.6835 36.0943 35.7805C36.7942 34.8414 37.2706 33.7551 37.4873 32.6042C37.7401 31.306 37.8633 29.9858 37.8548 28.6632C37.8633 27.3406 37.7401 26.0204 37.4873 24.7222C37.2708 23.5712 36.7944 22.4849 36.0943 21.546C35.4101 20.643 34.4668 19.9377 33.2681 19.4285C32.0676 18.9175 30.5258 18.6637 28.6411 18.6637H23.8793V26.2325V26.2307ZM19.0388 30.2312H16.4453V26.2325H19.0388V14.6632H30.4996C32.6171 14.6632 34.4493 15.0307 35.9981 15.7605C37.5468 16.4937 38.8103 17.493 39.7921 18.7617C40.7721 20.0287 41.5018 21.5127 41.9796 23.212C42.4573 24.9112 42.6953 26.7295 42.6953 28.6632C42.7073 30.5046 42.4659 32.3389 41.9778 34.1145C41.5018 35.812 40.7721 37.2977 39.7903 38.5647C38.8103 39.8335 37.5468 40.8327 35.9981 41.566C34.4493 42.2975 32.6153 42.6632 30.4996 42.6632H19.0388V30.2312Z"
      fill="white"
    />
  </svg>
)
export default DCoin
