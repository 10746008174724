const SafuBadgeIcon = () => (
  <svg width="247" height="39" viewBox="0 0 247 39" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M237.204 30.8273C235.019 30.8273 233.1 30.3742 231.448 29.4681C229.795 28.5354 228.503 27.2695 227.57 25.6705C226.637 24.0449 226.171 22.1927 226.171 20.114C226.171 18.0353 226.611 16.1964 227.49 14.5974C228.396 12.9984 229.622 11.7459 231.168 10.8398C232.74 9.90705 234.499 9.44067 236.444 9.44067C238.417 9.44067 240.162 9.89372 241.681 10.7998C243.227 11.6793 244.439 12.9318 245.319 14.5575C246.198 16.1565 246.638 18.0086 246.638 20.114C246.638 20.2472 246.625 20.3938 246.598 20.5537C246.598 20.687 246.598 20.8335 246.598 20.9934H228.33V18.8748H245.079L243.96 19.7142C243.96 18.1952 243.627 16.8494 242.96 15.6768C242.321 14.4775 241.441 13.5448 240.322 12.8785C239.203 12.2123 237.91 11.8791 236.444 11.8791C235.005 11.8791 233.713 12.2123 232.567 12.8785C231.421 13.5448 230.528 14.4775 229.889 15.6768C229.249 16.876 228.929 18.2485 228.929 19.7942V20.2339C228.929 21.8329 229.276 23.2454 229.969 24.4713C230.688 25.6705 231.674 26.6166 232.927 27.3095C234.206 27.9757 235.658 28.3089 237.284 28.3089C238.563 28.3089 239.749 28.0823 240.842 27.6293C241.961 27.1762 242.92 26.4833 243.72 25.5506L245.319 27.3894C244.386 28.5087 243.214 29.3615 241.801 29.9478C240.415 30.5341 238.883 30.8273 237.204 30.8273Z"
      fill="white"
    />
    <path
      d="M209.878 38.5824C207.959 38.5824 206.121 38.3026 204.362 37.743C202.603 37.1833 201.177 36.3838 200.084 35.3445L201.523 33.1858C202.51 34.0653 203.722 34.7582 205.161 35.2645C206.627 35.7975 208.173 36.064 209.798 36.064C212.463 36.064 214.422 35.4377 215.675 34.1852C216.927 32.9593 217.553 31.0405 217.553 28.4288V23.1921L217.953 19.5943L217.673 15.9966V9.64055H220.392V28.069C220.392 31.6934 219.499 34.3451 217.713 36.024C215.954 37.7296 213.343 38.5824 209.878 38.5824ZM209.359 29.8279C207.36 29.8279 205.561 29.4015 203.962 28.5487C202.363 27.6693 201.097 26.4567 200.164 24.911C199.258 23.3653 198.805 21.5931 198.805 19.5943C198.805 17.5956 199.258 15.8367 200.164 14.3176C201.097 12.7719 202.363 11.5727 203.962 10.7199C205.561 9.86707 207.36 9.44067 209.359 9.44067C211.224 9.44067 212.903 9.8271 214.395 10.5999C215.888 11.3728 217.074 12.5187 217.953 14.0378C218.833 15.5568 219.272 17.409 219.272 19.5943C219.272 21.7796 218.833 23.6318 217.953 25.1508C217.074 26.6699 215.888 27.8292 214.395 28.6287C212.903 29.4282 211.224 29.8279 209.359 29.8279ZM209.638 27.3095C211.184 27.3095 212.557 26.9897 213.756 26.3501C214.955 25.6838 215.901 24.7777 216.594 23.6318C217.287 22.4592 217.633 21.1134 217.633 19.5943C217.633 18.0753 217.287 16.7428 216.594 15.5968C215.901 14.4509 214.955 13.5581 213.756 12.9185C212.557 12.2522 211.184 11.9191 209.638 11.9191C208.119 11.9191 206.747 12.2522 205.521 12.9185C204.322 13.5581 203.376 14.4509 202.683 15.5968C202.017 16.7428 201.683 18.0753 201.683 19.5943C201.683 21.1134 202.017 22.4592 202.683 23.6318C203.376 24.7777 204.322 25.6838 205.521 26.3501C206.747 26.9897 208.119 27.3095 209.638 27.3095Z"
      fill="white"
    />
    <path
      d="M182.186 30.8271C180.187 30.8271 178.388 30.3741 176.789 29.468C175.217 28.5619 173.978 27.3093 173.072 25.7103C172.166 24.0847 171.713 22.2192 171.713 20.1138C171.713 17.9818 172.166 16.1163 173.072 14.5173C173.978 12.9183 175.217 11.6791 176.789 10.7997C178.388 9.89357 180.187 9.44052 182.186 9.44052C184.025 9.44052 185.664 9.85359 187.103 10.6797C188.569 11.5059 189.728 12.7185 190.581 14.3175C191.46 15.8898 191.9 17.8219 191.9 20.1138C191.9 22.3791 191.474 24.3112 190.621 25.9102C189.768 27.5092 188.609 28.7351 187.143 29.5879C185.704 30.4141 184.052 30.8271 182.186 30.8271ZM182.386 28.3087C183.878 28.3087 185.211 27.9756 186.383 27.3093C187.583 26.6164 188.515 25.657 189.182 24.4311C189.875 23.1786 190.221 21.7395 190.221 20.1138C190.221 18.4615 189.875 17.0224 189.182 15.7965C188.515 14.5706 187.583 13.6246 186.383 12.9583C185.211 12.2654 183.878 11.919 182.386 11.919C180.92 11.919 179.601 12.2654 178.428 12.9583C177.256 13.6246 176.323 14.5706 175.63 15.7965C174.937 17.0224 174.591 18.4615 174.591 20.1138C174.591 21.7395 174.937 23.1786 175.63 24.4311C176.323 25.657 177.256 26.6164 178.428 27.3093C179.601 27.9756 180.92 28.3087 182.386 28.3087ZM190.301 30.6273V24.3112L190.581 20.0739L190.181 15.8365V0.96582H193.019V30.6273H190.301Z"
      fill="white"
    />
    <path
      d="M163.404 30.6274V25.9903L163.284 25.2308V17.4756C163.284 15.6901 162.778 14.3176 161.765 13.3582C160.779 12.3988 159.3 11.9191 157.328 11.9191C155.969 11.9191 154.676 12.1456 153.45 12.5987C152.224 13.0517 151.185 13.6514 150.332 14.3976L149.053 12.2789C150.119 11.3728 151.398 10.6799 152.891 10.2002C154.383 9.69385 155.955 9.44067 157.608 9.44067C160.326 9.44067 162.418 10.1202 163.884 11.4794C165.376 12.8119 166.122 14.8506 166.122 17.5956V30.6274H163.404ZM156.169 30.8273C154.596 30.8273 153.224 30.5741 152.051 30.0678C150.905 29.5348 150.026 28.8152 149.413 27.9091C148.8 26.9764 148.493 25.9104 148.493 24.7111C148.493 23.6185 148.747 22.6324 149.253 21.753C149.786 20.8469 150.639 20.1273 151.811 19.5943C153.011 19.0347 154.61 18.7548 156.608 18.7548H163.844V20.8735H156.688C154.663 20.8735 153.25 21.2333 152.451 21.9528C151.678 22.6724 151.292 23.5652 151.292 24.6312C151.292 25.8304 151.758 26.7898 152.691 27.5094C153.624 28.2289 154.929 28.5887 156.608 28.5887C158.207 28.5887 159.58 28.2289 160.726 27.5094C161.898 26.7632 162.751 25.6972 163.284 24.3114L163.924 26.2701C163.391 27.6559 162.458 28.7619 161.126 29.5881C159.82 30.4142 158.167 30.8273 156.169 30.8273Z"
      fill="white"
    />
    <path
      d="M134.11 30.8271C132.271 30.8271 130.619 30.4141 129.153 29.5879C127.688 28.7351 126.528 27.5092 125.676 25.9102C124.823 24.3112 124.396 22.3791 124.396 20.1138C124.396 17.8219 124.823 15.8898 125.676 14.3175C126.555 12.7185 127.728 11.5059 129.193 10.6797C130.659 9.85359 132.298 9.44052 134.11 9.44052C136.109 9.44052 137.895 9.89357 139.467 10.7997C141.066 11.6791 142.319 12.9183 143.225 14.5173C144.131 16.1163 144.584 17.9818 144.584 20.1138C144.584 22.2192 144.131 24.0847 143.225 25.7103C142.319 27.3093 141.066 28.5619 139.467 29.468C137.895 30.3741 136.109 30.8271 134.11 30.8271ZM123.277 30.6273V0.96582H126.115V15.8365L125.716 20.0739L125.995 24.3112V30.6273H123.277ZM133.91 28.3087C135.403 28.3087 136.735 27.9756 137.908 27.3093C139.081 26.6164 140.013 25.657 140.706 24.4311C141.399 23.1786 141.746 21.7395 141.746 20.1138C141.746 18.4615 141.399 17.0224 140.706 15.7965C140.013 14.5706 139.081 13.6246 137.908 12.9583C136.735 12.2654 135.403 11.919 133.91 11.919C132.418 11.919 131.072 12.2654 129.873 12.9583C128.7 13.6246 127.768 14.5706 127.075 15.7965C126.408 17.0224 126.075 18.4615 126.075 20.1138C126.075 21.7395 126.408 23.1786 127.075 24.4311C127.768 25.657 128.7 26.6164 129.873 27.3093C131.072 27.9756 132.418 28.3087 133.91 28.3087Z"
      fill="white"
    />
    <path
      d="M93.0277 30.8674C89.4566 30.8674 86.645 29.8413 84.593 27.7893C82.5409 25.7372 81.5149 22.6991 81.5149 18.675V2.64502H84.473V18.5551C84.473 21.8597 85.2192 24.2981 86.7116 25.8705C88.204 27.4428 90.3094 28.229 93.0277 28.229C95.7726 28.229 97.8913 27.4428 99.3837 25.8705C100.876 24.2981 101.622 21.8597 101.622 18.5551V2.64502H104.5V18.675C104.5 22.6991 103.474 25.7372 101.422 27.7893C99.397 29.8413 96.5988 30.8674 93.0277 30.8674Z"
      fill="white"
    />
    <path
      d="M59.0964 16.1965H73.8871V18.7549H59.0964V16.1965ZM59.4162 30.6275H56.458V2.64502H75.646V5.20342H59.4162V30.6275Z"
      fill="white"
    />
    <path
      d="M23.3269 30.6275L36.1189 2.64502H39.0371L51.8291 30.6275H48.711L36.9584 4.36395H38.1576L26.405 30.6275H23.3269ZM28.3638 23.1522L29.2432 20.7537H45.513L46.3925 23.1522H28.3638Z"
      fill="white"
    />
    <path
      d="M10.9252 30.8672C8.84654 30.8672 6.84778 30.5341 4.92899 29.8678C3.03684 29.1749 1.57109 28.2955 0.531738 27.2295L1.69101 24.9509C2.67706 25.9103 3.99624 26.7231 5.64854 27.3894C7.32748 28.029 9.08638 28.3488 10.9252 28.3488C12.6841 28.3488 14.1099 28.1356 15.2026 27.7092C16.3219 27.2561 17.1347 26.6565 17.641 25.9103C18.174 25.1641 18.4405 24.338 18.4405 23.4319C18.4405 22.3392 18.1207 21.4598 17.4811 20.7935C16.8682 20.1273 16.0554 19.6076 15.0427 19.2345C14.03 18.8347 12.9107 18.4883 11.6848 18.1951C10.4589 17.902 9.23296 17.5955 8.00706 17.2757C6.78116 16.9293 5.64854 16.4762 4.60919 15.9166C3.59649 15.3569 2.77034 14.624 2.13074 13.7179C1.51779 12.7852 1.21131 11.5726 1.21131 10.0802C1.21131 8.69443 1.57109 7.42855 2.29064 6.2826C3.03684 5.11 4.16946 4.17725 5.68851 3.48435C7.20756 2.7648 9.15301 2.40503 11.5249 2.40503C13.0972 2.40503 14.6562 2.63155 16.2019 3.0846C17.7476 3.511 19.0801 4.11063 20.1994 4.88348L19.2001 7.242C18.0008 6.4425 16.7216 5.8562 15.3625 5.4831C14.03 5.11 12.7374 4.92345 11.4849 4.92345C9.80593 4.92345 8.42013 5.14998 7.32748 5.60303C6.23483 6.05608 5.42201 6.66903 4.88901 7.44188C4.38266 8.18808 4.12949 9.04088 4.12949 10.0003C4.12949 11.0929 4.43596 11.9724 5.04891 12.6386C5.68851 13.3049 6.51466 13.8245 7.52736 14.1976C8.56671 14.5707 9.69934 14.9039 10.9252 15.197C12.1511 15.4902 13.3637 15.81 14.563 16.1564C15.7889 16.5029 16.9082 16.9559 17.9209 17.5156C18.9602 18.0486 19.7864 18.7681 20.3993 19.6742C21.0389 20.5803 21.3587 21.7662 21.3587 23.232C21.3587 24.5911 20.9856 25.857 20.2394 27.0296C19.4932 28.1756 18.3473 29.1083 16.8016 29.8279C15.2825 30.5208 13.3237 30.8672 10.9252 30.8672Z"
      fill="white"
    />
  </svg>
)

export default SafuBadgeIcon
