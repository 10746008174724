const YoutubeIcon = () => (
  <svg width="36" height="37" viewBox="0 0 36 37" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17.9522 36.2531C27.7221 36.2531 35.6422 28.333 35.6422 18.563C35.6422 8.79313 27.7221 0.873047 17.9522 0.873047C8.18228 0.873047 0.262207 8.79313 0.262207 18.563C0.262207 28.333 8.18228 36.2531 17.9522 36.2531Z"
      fill="#FDDF84"
    />
    <path
      d="M27.422 16.0332C27.422 14.906 26.9742 13.825 26.1772 13.028C25.3802 12.231 24.2992 11.7832 23.172 11.7832H12.312C11.1848 11.7832 10.1038 12.231 9.30676 13.028C8.50973 13.825 8.06201 14.906 8.06201 16.0332V21.0832C8.06201 22.2104 8.50973 23.2914 9.30676 24.0884C10.1038 24.8854 11.1848 25.3332 12.312 25.3332H23.222C24.3492 25.3332 25.4301 24.8854 26.2272 24.0884C27.0242 23.2914 27.472 22.2104 27.472 21.0832L27.422 16.0332ZM21.032 18.9332L16.162 21.3432C15.972 21.4532 15.322 21.3432 15.322 21.0932V16.1432C15.322 15.9232 15.972 15.7932 16.162 15.9032L20.832 18.4332C21.022 18.5432 21.222 18.8332 21.032 18.9332Z"
      fill="#0A1C32"
    />
  </svg>
)
export default YoutubeIcon
