const TelegramIcon = () => (
  <svg width="35" height="36" viewBox="0 0 35 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17.2543 35.2532C26.7812 35.2532 34.5044 27.53 34.5044 18.003C34.5044 8.47607 26.7812 0.75293 17.2543 0.75293C7.72728 0.75293 0.00415039 8.47607 0.00415039 18.003C0.00415039 27.53 7.72728 35.2532 17.2543 35.2532Z"
      fill="#FDDF84"
    />
    <path
      d="M25.3033 11.4192C25.2299 11.7976 25.1586 12.1766 25.0823 12.5544C24.7255 14.3225 24.3678 16.0903 24.0091 17.8579C23.588 19.937 23.1671 22.0159 22.7463 24.0945C22.6792 24.4262 22.6131 24.7581 22.5457 25.0901C22.5383 25.1261 22.5285 25.162 22.5189 25.1976C22.2744 26.1173 21.5875 26.2206 20.9131 25.7401C19.8176 24.9598 18.7459 24.1463 17.6641 23.3469C17.3957 23.1484 17.1235 22.9542 16.8596 22.751C16.7686 22.681 16.7136 22.6915 16.6328 22.7703C16.0072 23.3807 15.3782 23.9876 14.7459 24.5912C14.6053 24.7278 14.4523 24.851 14.2889 24.9591C13.9391 25.1858 13.547 25.0946 13.3613 24.7225C13.2204 24.4405 13.1199 24.1365 13.021 23.8356C12.5697 22.4638 12.1228 21.0905 11.6804 19.7157C11.6409 19.5933 11.5802 19.5274 11.453 19.4883C10.1791 19.0962 8.90904 18.6959 7.63483 18.3009C7.40874 18.231 7.21317 18.1267 7.10695 17.9031C6.95686 17.5877 7.09011 17.2134 7.44594 17.0165C7.63865 16.91 7.85297 16.841 8.06031 16.7621C11.4883 15.4632 14.9167 14.165 18.3456 12.8673C20.2158 12.1592 22.0866 11.4525 23.9579 10.7469C24.1487 10.6751 24.3503 10.6038 24.5506 10.5908C24.978 10.5628 25.2598 10.843 25.2801 11.2716C25.2826 11.3193 25.2801 11.367 25.2801 11.4147L25.3033 11.4192ZM13.8609 23.9253L13.8841 23.9221C13.9986 22.9179 14.1165 21.914 14.225 20.9094C14.2463 20.7123 14.3032 20.5615 14.4612 20.42C16.9401 18.2006 19.4148 15.9767 21.8852 13.7482C21.9243 13.7129 21.9822 13.6801 21.9943 13.6362C22.0191 13.5462 22.0187 13.4493 22.0289 13.3554C21.945 13.3484 21.8597 13.3306 21.7774 13.3389C21.7236 13.3443 21.6715 13.3844 21.6222 13.4149C21.3835 13.5633 21.1454 13.7117 20.908 13.8601L13.2923 18.6389C12.9743 18.8383 12.6563 19.0387 12.3335 19.2431L13.8609 23.9253Z"
      fill="#0A1C32"
    />
  </svg>
)
export default TelegramIcon
