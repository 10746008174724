import Image from 'next/image'
import { Element } from 'react-scroll'
import styled from 'styled-components'
import ArrowDownIcon from './ArrowDownIcon'
import BinanceIcon from './BinanceIcon'
import BitcoinIcon from './BitcoinIcon'
import CadanoIcon from './CadanoIcon'
import ContractIcon from './ContractIcon'
import DCoin from './DCoin'
import DividerIcon from './DividerIcon'
import EthIcon from './EthIcon'
import LayerIcon from './LayerIcon'
import LeftArrowIcon from './LeftArrowIcon'
import RightArrowIcon from './RightArrowIcon'
import SolanaIcon from './SolanaIcon'
import TronIcon from './TronIcon'
import UniswapIcon from './UniswapIcon'
import UserIcon from './UserIcon'
import UsdtIcon from './UsdtIcon'
import ByteCoinIcon from './ByteCoin'

export const CircleBlured = styled.div`
  width: 250px;
  height: 250px;
  background: #fef8b620;
  position: absolute;
  border-radius: 100px;
  z-index: 1;
  box-shadow: 0px 0px 400px 170px #fef8b620;
`
const HomeContainerStyle = styled.div`
  display: flex;

  justify-content: space-between;
  width: 100%;
  max-width: 850px;

  @media (max-width: 1025px) {
    flex-direction: column;
    margin-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
  }
`

const LeftContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (max-width: 1025px) {
    padding-left: 0;
    align-items: center;
    text-align: left;
  }
`

const RightContent = styled.div`
  padding-top: 154px;

  @media (max-width: 1025px) {
    padding-right: 0;
    padding-top: 20px;
  }
`

const Container = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
  align-items: center;
  position: relative;

  @media (max-width: 1025px) {
    flex-direction: column;
  }
`

const Frame = styled.div`
  background-color: #ffffff10;
  border-radius: 21px;
  width: 100%;
  max-width: 1200px;
  height: 611.83px;
  padding: 40px 60px;

  @media (max-width: 1025px) {
    padding: 20px;
    height: auto;
    display: none;
  }
`
const DescriptionContent = styled.div`
  width: 966.39px;
  text-align: center;
  font-size: 26px;
  font-weight: 600;
  line-height: 31.69px;
  padding: 0 80px;
  margin-top: 50px;
  @media (max-width: 1025px) {
    padding: 20px;
    height: auto;
  }
`

const Title = styled.div`
  font-size: 50px;
  font-weight: 700;
  background-image: linear-gradient(to right, #f7d67c 0%, #d19f48 25%, #f7d67c 75%, #d19f48 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  white-space: pre-line;
  text-align: left;
  margin-top: 4px;
`

const HomeContainer = () => {
  return (
    <Element name="home">
      <div style={{ position: 'relative' }}>
        <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
          <HomeContainerStyle id="home">
            <LeftContent data-aos="fade-right">
              <div style={{ fontSize: 50, fontWeight: 700, textAlign: 'left' }}>UDEFI</div>
              <Title
              // style={{
              //   fontSize: 50,
              //   fontWeight: 700,
              //   backgroundImage: 'linear-gradient(to right, #F7D67C 0%, #D19F48 25% ,#F7D67C 75%, #D19F48 100%)',
              //   backgroundClip: 'text',
              //   WebkitTextFillColor: 'transparent',
              //   whiteSpace: 'pre-line',
              //   textAlign: 'left',
              //   marginTop: 4,
              // }}
              >{`1000% APY \nDaily Earn`}</Title>
              <div
                style={{
                  fontSize: 20,
                  fontWeight: 600,
                  width: 360,
                  marginTop: 15,
                  lineHeight: '24.38px',
                  textAlign: 'left',
                }}
              >{`UDEFI - The project leading the "DeFi2.0" trend with 1000% APY and compound interest per year.`}</div>
            </LeftContent>
            <RightContent data-aos="fade-left">
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <CircleBlured />
                <div style={{ width: 361, height: 424 }}>
                  <Image
                    width={361}
                    height={424}
                    style={{ zIndex: 1 }}
                    src="/images/home/sample-connect.png"
                    alt="home"
                  />
                </div>
              </div>
            </RightContent>
          </HomeContainerStyle>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: 80,
          }}
        >
          <div
            style={{
              height: 1,
              background: '#FDDF84',
              width: '668px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: 60,
                height: 40,
                margin: 8,
                background: '#0B0B0B',
              }}
            >
              <DividerIcon />
            </div>
          </div>
        </div>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <DescriptionContent>
            UDEFI aims to build the NFT ecosystem alongside the global DeFi2.0 revolution, focusing on incubating
            Metaverse and Web3, GameFi, SocialFi and NFT unicorns.
          </DescriptionContent>
        </div>
        <Container>
          <div style={{ position: 'absolute', left: -300, top: 170 }}>
            <CircleBlured />
          </div>
          <Frame>
            <div
              data-aos="fade-right"
              style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
            >
              <div style={{ height: 220, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                <div>Defi 2.0 Project</div>
                <div />
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div style={{}}>
                    <LayerIcon />
                  </div>
                </div>
                <div />
              </div>

              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  height: 220,
                }}
              >
                <div>Token Reactor</div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div style={{ margin: 30 }}>
                    <RightArrowIcon />
                  </div>
                  <div
                    style={{
                      border: '1px solid #F3F3F3',
                      borderRadius: 16,
                      padding: '20px 50px',
                      display: 'grid',
                      gridTemplateColumns: 'repeat(5, 1fr)',
                      gridGap: 20,
                    }}
                  >
                    <BitcoinIcon />
                    <EthIcon />
                    <ByteCoinIcon width={56} height={56} />
                    <BinanceIcon />
                    <CadanoIcon />
                    <DCoin />
                    <UniswapIcon />
                    <UsdtIcon width={56} height={56} />
                    <SolanaIcon />
                    <TronIcon />
                  </div>
                  <div style={{ marginLeft: 30 }}>
                    <LeftArrowIcon />
                  </div>
                </div>
              </div>

              <div
                data-aos="fade-left"
                style={{
                  height: 220,
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <div>Token Project Users</div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div />
                  <div />

                  <div style={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gap: 13 }}>
                    <UserIcon />
                    <UserIcon />
                    <UserIcon />
                    <UserIcon />
                    <UserIcon />
                    <UserIcon />
                  </div>
                </div>

                <div />
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                marginTop: 20,
              }}
            >
              <div style={{ height: 30, background: '#FFFFFF', width: 2, marginBottom: 13 }} />
              <div>Liquidity Directors</div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <div
                style={{
                  height: 2,
                  background: '#FFFFFF',
                  width: 800,
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginTop: 19,
                }}
              >
                <div style={{ marginLeft: -60, display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <ArrowDownIcon />
                    <div
                      style={{
                        marginTop: 14,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                      }}
                    >
                      <ContractIcon />
                      <div style={{ color: '#FDDF84', fontWeight: '700', marginTop: 8, marginBottom: 8 }}>AMM 1</div>
                      <div style={{ fontSize: 20, fontWeight: 600 }}>Pool Contract</div>
                    </div>
                  </div>
                </div>
                <div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      marginLeft: 15,
                    }}
                  >
                    <ArrowDownIcon />
                    <div
                      style={{
                        marginTop: 14,
                        marginLeft: 10,
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'column',
                      }}
                    >
                      <ContractIcon />
                      <div style={{ fontSize: 20, fontWeight: '700', color: '#FDDF84', marginTop: 8, marginBottom: 8 }}>
                        AMM 1
                      </div>
                      <div style={{ fontSize: 20, fontWeight: 600 }}>Pool Contract</div>
                    </div>
                  </div>
                </div>
                <div style={{ marginRight: -78 }}>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                    }}
                  >
                    <ArrowDownIcon />
                    <div
                      style={{
                        marginTop: 14,
                        marginRight: -5,
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'column',
                      }}
                    >
                      <ContractIcon />
                      <div style={{ fontSize: 20, fontWeight: '700', color: '#FDDF84', marginTop: 8, marginBottom: 8 }}>
                        Cross-Chain AMM
                      </div>
                      <div style={{ fontSize: 20, fontWeight: 600 }}>Pool Contract</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Frame>
        </Container>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <div style={{ position: 'absolute', bottom: -190, marginLeft: -200 }}>
            <CircleBlured />
          </div>
        </div>
      </div>
    </Element>
  )
}
export default HomeContainer
