const DividerIcon = () => (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19.8628 39.6709C19.8628 28.8077 28.6745 19.996 39.5377 19.996C28.6745 19.996 19.8628 11.1844 19.8628 0.321167C19.8628 11.1844 11.0511 19.996 0.172852 19.996C11.0511 19.996 19.8628 28.8077 19.8628 39.6709Z"
      fill="url(#paint0_linear_40_7)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_40_7"
        x1="0.172852"
        y1="19.996"
        x2="39.5377"
        y2="19.996"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#E9CD87" />
        <stop offset="0.09" stopColor="#E2BF73" />
        <stop offset="0.24" stopColor="#DAAC5A" />
        <stop offset="0.33" stopColor="#D7A651" />
        <stop offset="0.37" stopColor="#E6BD65" />
        <stop offset="0.42" stopColor="#F2CF76" />
        <stop offset="0.47" stopColor="#FADB80" />
        <stop offset="0.53" stopColor="#FDDF84" />
        <stop offset="0.65" stopColor="#E6BE65" />
        <stop offset="0.79" stopColor="#D3A24B" />
        <stop offset="0.91" stopColor="#C8913B" />
        <stop offset="1" stopColor="#C48B36" />
      </linearGradient>
    </defs>
  </svg>
)

export default DividerIcon
