const TronIcon = () => (
  <svg width="56" height="57" viewBox="0 0 56 57" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M28.0057 56.6632C43.4697 56.6632 56.0057 44.1272 56.0057 28.6632C56.0057 13.1992 43.4697 0.663208 28.0057 0.663208C12.5418 0.663208 0.0057373 13.1992 0.0057373 28.6632C0.0057373 44.1272 12.5418 56.6632 28.0057 56.6632Z"
      fill="#EF0027"
    />
    <path
      d="M38.3867 18.0109L13.1307 13.3629L26.422 46.8089L44.9422 24.2444L38.3867 18.0109ZM37.9807 20.0584L41.8447 23.7317L31.2782 25.6444L37.9807 20.0584ZM28.9822 25.2612L17.8452 16.0247L36.0487 19.3742L28.9822 25.2612ZM28.1895 26.8957L26.373 41.9107L16.5817 17.2654L28.1895 26.8957ZM29.8695 27.6919L41.5717 25.5744L28.1492 41.9247L29.8695 27.6919Z"
      fill="white"
    />
  </svg>
)
export default TronIcon
