const SolanaIcon = () => (
  <svg width="57" height="57" viewBox="0 0 57 57" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M28.4355 56.6632C43.8995 56.6632 56.4355 44.1272 56.4355 28.6632C56.4355 13.1992 43.8995 0.663208 28.4355 0.663208C12.9715 0.663208 0.435486 13.1992 0.435486 28.6632C0.435486 44.1272 12.9715 56.6632 28.4355 56.6632Z"
      fill="#F9F7F3"
    />
    <path
      d="M17.8042 35.1155C17.9977 34.9246 18.2586 34.8177 18.5304 34.818H43.6709C43.7723 34.8166 43.8718 34.8456 43.9565 34.9013C44.0412 34.9569 44.1073 35.0367 44.1463 35.1303C44.1853 35.2239 44.1953 35.327 44.1752 35.4263C44.155 35.5257 44.1056 35.6167 44.0332 35.6877L39.0667 40.614C38.8735 40.8055 38.6125 40.913 38.3404 40.9132H13.1999C13.0986 40.9141 12.9993 40.8847 12.9147 40.8288C12.8302 40.7729 12.7642 40.693 12.7253 40.5994C12.6864 40.5058 12.6764 40.4028 12.6964 40.3034C12.7164 40.2041 12.7656 40.1129 12.8377 40.0417L17.8042 35.1155ZM17.8042 16.7107C17.9977 16.5198 18.2586 16.4129 18.5304 16.4132H43.6709C44.1277 16.4132 44.3569 16.9627 44.0332 17.2847L39.0667 22.211C38.8732 22.4019 38.6122 22.5087 38.3404 22.5085H13.1999C13.0989 22.509 13.0001 22.4794 12.9159 22.4236C12.8318 22.3678 12.7661 22.2882 12.7273 22.195C12.6884 22.1018 12.6782 21.9991 12.6979 21.9001C12.7175 21.801 12.7662 21.71 12.8377 21.6387L17.8042 16.7107ZM39.0667 25.8545C38.8732 25.6636 38.6122 25.5567 38.3404 25.557H13.1999C13.0986 25.5561 12.9993 25.5855 12.9147 25.6414C12.8302 25.6973 12.7642 25.7771 12.7253 25.8707C12.6864 25.9643 12.6764 26.0674 12.6964 26.1667C12.7164 26.2661 12.7656 26.3572 12.8377 26.4285L17.8042 31.3547C17.9967 31.5455 18.2592 31.6522 18.5304 31.6522H43.6709C43.7723 31.6531 43.8716 31.6237 43.9561 31.5678C44.0407 31.5119 44.1066 31.432 44.1455 31.3384C44.1844 31.2448 44.1945 31.1418 44.1745 31.0424C44.1545 30.9431 44.1053 30.8519 44.0332 30.7807L39.0667 25.8545Z"
      fill="url(#paint0_linear_0_96)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_0_96"
        x1="28.4358"
        y1="16.4132"
        x2="28.4358"
        y2="40.9132"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#2CCEBF" />
        <stop offset="0.46" stopColor="#718BD4" />
        <stop offset="1" stopColor="#9467DF" />
      </linearGradient>
    </defs>
  </svg>
)
export default SolanaIcon
