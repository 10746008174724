const FacebookIcon = () => (
  <svg width="37" height="37" viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18.3834 36.2531C28.1533 36.2531 36.0734 28.333 36.0734 18.563C36.0734 8.79313 28.1533 0.873047 18.3834 0.873047C8.61344 0.873047 0.693359 8.79313 0.693359 18.563C0.693359 28.333 8.61344 36.2531 18.3834 36.2531Z"
      fill="#FDDF84"
    />
    <path
      d="M23.5352 20.1597V16.2032H19.6512V13.6454C19.6237 13.3502 19.6646 13.0527 19.7707 12.775C19.8768 12.4972 20.0454 12.2463 20.2642 12.0408C20.4829 11.8353 20.7461 11.6805 21.0343 11.5877C21.3226 11.495 21.6284 11.4667 21.9294 11.5049H23.6934V8.14351C22.6471 7.97616 21.5894 7.88575 20.5292 7.87305C17.3651 7.87305 15.2451 9.76627 15.2451 13.1973V16.2032H11.6934V20.1597H15.3084V29.7108C16.7581 29.9271 18.2331 29.9271 19.6829 29.7108V20.1597H23.5352Z"
      fill="#0A1C32"
    />
  </svg>
)

export default FacebookIcon
