const BinanceIcon = () => (
  <svg width="57" height="57" viewBox="0 0 57 57" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M28.435 56.7435C43.899 56.7435 56.435 44.2075 56.435 28.7435C56.435 13.2796 43.899 0.74353 28.435 0.74353C12.971 0.74353 0.434998 13.2796 0.434998 28.7435C0.434998 44.2075 12.971 56.7435 28.435 56.7435Z"
      fill="#0B0E11"
    />
    <path
      d="M20.639 25.5405L28.439 17.7405L36.239 25.5405L40.775 21.0045L28.434 8.67255L16.102 21.0045L20.639 25.5405ZM12.9 24.2076L17.436 28.7435L12.9 33.2795L8.363 28.7435L12.9 24.2076ZM20.639 31.9475L28.439 39.7475L36.239 31.9475L40.778 36.4815L28.442 48.8175L16.102 36.4835L16.096 36.4775L20.639 31.9475ZM48.507 28.7475L43.971 33.2835L39.435 28.7475L43.971 24.2115L48.507 28.7475Z"
      fill="#F0B90B"
    />
    <path
      d="M33.038 28.7415L28.438 24.1415L23.833 28.7465L23.839 28.7525L28.439 33.3525L33.038 28.7475V28.7415Z"
      fill="#F0B90B"
    />
  </svg>
)
export default BinanceIcon
