const BitcoinIcon = () => (
  <svg width="57" height="57" viewBox="0 0 57 57" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M28.6953 56.7435C44.1593 56.7435 56.6953 44.2075 56.6953 28.7435C56.6953 13.2796 44.1593 0.74353 28.6953 0.74353C13.2313 0.74353 0.695312 13.2796 0.695312 28.7435C0.695312 44.2075 13.2313 56.7435 28.6953 56.7435Z"
      fill="#F7931A"
    />
    <path
      d="M41.276 25.2785C41.8255 21.6105 39.0307 19.6383 35.2122 18.3223L36.4512 13.3523L33.4272 12.5998L32.2197 17.4385C31.4252 17.239 30.6097 17.0535 29.796 16.868L31.0122 11.9978L27.9882 11.2435L26.7492 16.2118C26.0912 16.0613 25.4437 15.9143 24.8172 15.7568L24.8207 15.741L20.6487 14.6998L19.8437 17.9303C19.8437 17.9303 22.089 18.4448 22.0417 18.4763C23.2667 18.7825 23.4872 19.5928 23.4505 20.2368L22.04 25.898C22.124 25.919 22.2325 25.9505 22.355 25.9978L22.0347 25.919L20.0572 33.85C19.9067 34.221 19.527 34.7793 18.6695 34.5675C18.701 34.6113 16.4715 34.0198 16.4715 34.0198L14.97 37.4813L18.9075 38.463C19.639 38.6468 20.3565 38.8393 21.0617 39.0195L19.8105 44.0455L22.8327 44.798L24.0717 39.828C24.8977 40.0503 25.6992 40.2568 26.4832 40.4528L25.2477 45.4018L28.2717 46.1543L29.523 41.1388C34.682 42.1153 38.56 41.7215 40.1927 37.056C41.5087 33.3005 40.128 31.1323 37.4137 29.72C39.3912 29.265 40.8787 27.9648 41.276 25.2785ZM34.3635 34.97C33.4307 38.7273 27.1045 36.6955 25.0535 36.1863L26.716 29.5275C28.767 30.0403 35.3417 31.0535 34.3635 34.97ZM35.2997 25.2243C34.4475 28.642 29.1835 26.9043 27.4772 26.479L28.9822 20.4415C30.6885 20.8668 36.1887 21.6595 35.2997 25.2243Z"
      fill="white"
    />
  </svg>
)
export default BitcoinIcon
