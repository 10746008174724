const UserIcon = () => (
  <svg width="36" height="37" viewBox="0 0 36 37" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.1266 0.847656C8.33333 0.847656 0.394775 8.78621 0.394775 18.5795C0.394775 28.3728 8.33333 36.3114 18.1266 36.3114C27.9199 36.3114 35.8585 28.3728 35.8585 18.5795C35.8585 8.78621 27.9199 0.847656 18.1266 0.847656ZM11.9205 14.1466C11.9205 13.3316 12.081 12.5245 12.3929 11.7716C12.7048 11.0186 13.1619 10.3344 13.7382 9.75814C14.3145 9.18185 14.9987 8.72471 15.7516 8.41282C16.5046 8.10093 17.3116 7.9404 18.1266 7.9404C18.9416 7.9404 19.7487 8.10093 20.5016 8.41282C21.2546 8.72471 21.9388 9.18185 22.5151 9.75814C23.0913 10.3344 23.5485 11.0186 23.8604 11.7716C24.1723 12.5245 24.3328 13.3316 24.3328 14.1466C24.3328 15.7925 23.6789 17.3711 22.5151 18.535C21.3512 19.6988 19.7726 20.3527 18.1266 20.3527C16.4807 20.3527 14.9021 19.6988 13.7382 18.535C12.5743 17.3711 11.9205 15.7925 11.9205 14.1466ZM29.2232 27.4171C27.8959 29.0868 26.2085 30.435 24.287 31.361C22.3655 32.2871 20.2596 32.767 18.1266 32.765C15.9936 32.767 13.8878 32.2871 11.9663 31.361C10.0448 30.435 8.35738 29.0868 7.03004 27.4171C9.90437 25.3549 13.8267 23.8991 18.1266 23.8991C22.4266 23.8991 26.3489 25.3549 29.2232 27.4171Z"
      fill="white"
    />
  </svg>
)
export default UserIcon
