const ByteCoinIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" id="Laag_1" width={2000} height={2000} viewBox="0 0 1984.3 1984.3" {...props}>
    <style>{'.st0{fill:#ED4085}'}</style>
    <g id="Laag_2">
      <g id="BCN">
        <path
          d="M1139.2 1020.9H898.6V1320h242.5c108.4 0 224.6-38.7 224.6-135.8-.1-97.1-66.5-163.3-226.5-163.3zm146.3-253.5c0-93.7-88.2-134.9-173.6-134.9H898.6V908h208c86 .6 178.9-46.7 178.9-140.6z"
          className="st0"
          stroke="#FFFFFF"
        />
        <path
          d="M992.2 0C444.2 0 0 444.2 0 992.2s444.2 992.2 992.2 992.2 992.2-444.2 992.2-992.2S1540.1 0 992.2 0zm159.1 1482.6H722.1v-254.2H458.7v-129h263.4v-171H458.7v-129h263.4V497h463.6c165.4 0 270 138.1 270 246.4S1356.4 913 1356.4 913c106.1 40.9 174.8 144.2 171.6 257.8 0 201.1-173.8 311.8-376.7 311.8z"
          className="st0"
          stroke="#FFFFFF"
        />
      </g>
    </g>
  </svg>
)
export default ByteCoinIcon
